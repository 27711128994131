// ============================
//     Avatar css start
// ============================

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  font: {
    size: 18px;
    weight: 600;
  }
  width: 48px;
  height: 48px;

  &.avatar-xxs {
    width: 22px;
    height: 22px;
    font-size: 14px;
    border-radius: 8px;
  }

  &.avatar-xs {
    width: 32px;
    height: 32px;
    font-size: 12px;
  }

  &.avatar-s {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }

  &.avatar-l {
    width: 64px;
    height: 64px;
    font-size: 20px;
  }

  &.avatar-xl {
    width: 80px;
    height: 80px;
    font-size: 24px;
  }

  &.avatar-square {
    border-radius: 4px;
    border: 2px solid;
  }

  &.avatar-icon {
    border: 2px solid $border-color;

    > img {
      width: 60%;
    }
  }
}
// Avatar css end
