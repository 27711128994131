.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.weekend {
  background-color: #f0ad4e;
  border-radius: 1rem;
  color: white;
}
.hidden {
  display: none;
}
