body.berry-rtl {
  direction: rtl;
  text-align: right;
  position: unset;

  $i: 0;

  @while $i<=50 {
    .p {
      &-l-#{$i} {
        padding-right: #{$i}px;
        padding-left: 0;

        @if ($i ==0) {
          &[class*='col'] {
            padding-left: 15px;
          }
        }
      }

      &-r-#{$i} {
        padding-left: #{$i}px;
        padding-right: 0;

        @if ($i ==0) {
          &[class*='col'] {
            padding-right: 15px;
          }
        }
      }
    }

    .m {
      &-l-#{$i} {
        margin-right: #{$i}px;
        margin-left: 0;
      }

      &-r-#{$i} {
        margin-left: #{$i}px;
        margin-right: 0;
      }
    }

    $i: $i + 5;
  }

  ul {
    padding-right: 0;
  }

  // live customize

  .menu-styler {
    &.open {
      .style-block {
        right: auto;
        left: 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }
    }

    .style-block {
      right: auto;
      left: -375px;
    }
  }

  .pct-c-btn {
    border-radius: 50% 50% 50% 4px;
    left: 0px;
    right: auto;
  }

  // ======= Scrollbar =====///
  .ng-scroll-viewport {
    direction: rtl;
    text-align: right;
  }

  // menu and header
  .pc-container {
    margin-right: 260px;
    margin-left: 20px;

    @media (max-width: 1024px) {
      margin-right: 20px !important;
    }
  }

  .pc-sidebar.compact {
    .pc-compact-submenu ::after {
      right: 0;
      left: auto;
    }

    ~ .pc-container {
      margin-right: 80px;
      margin-left: 20px;
    }

    .m-header a {
      direction: ltr;
    }

    &.navbar-collapsed {
      ~ .pc-container {
        margin-right: 20px;
      }
    }

    .coded-inner-navbar li {
      &.coded-hasmenu > a {
        &:after {
          content: '\e843';
          font-family: 'feather';
          left: 2px;
          top: 13px;
        }
      }

      > a {
        > .coded-micon {
          margin-left: 0px;
        }
      }
    }
  }

  .pc-sidebar.navbar-collapsed {
    ~ .pc-container,
    ~ .pc-footer {
      margin-right: 20px;
    }
  }

  .pc-sidebar {
    .coded-inner-navbar {
      li {
        > a {
          > .coded-micon {
            margin-left: 15px;
            margin-right: 0px;
          }
        }

        &.coded-hasmenu {
          > a:after {
            left: 20px;
            right: auto;
          }

          .coded-submenu {
            > app-nav-item li,
            > app-nav-collapse li {
              > a {
                text-align: right;
                padding: 10px 75px 10px 30px;

                &::before {
                  right: 55px;
                  left: auto;
                }
              }

              .coded-submenu {
                > app-nav-item li {
                  > a {
                    padding: 7px 95px 7px 7px;

                    &::before {
                      right: 75px;
                      left: auto;
                    }
                  }
                }

                > app-nav-collapse li {
                  > a {
                    padding: 7px 95px 7px 7px;

                    &::after {
                      left: 30px;
                    }
                  }

                  .coded-submenu {
                    > app-nav-item li {
                      > a {
                        padding: 7px 115px 7px 7px;

                        &:before {
                          left: 95px;
                        }
                      }
                    }
                  }
                }
              }
            }

            > app-nav-collapse li {
              > a::after {
                left: 30px;
              }
            }
          }
        }
      }

      app-nav-item > li.nav-item,
      > app-nav-group > app-nav-collapse > li {
        &.active,
        &.coded-trigger {
          > ul {
            &:before {
              left: auto;
              right: 40px;
            }
          }
        }
      }
    }
  }

  .pc-header {
    .pc-mega-menu .pc-mega-drop-menu {
      left: 120px !important;
      right: auto !important;
    }

    .pc-h-dropdown.dropdown-menu-end {
      right: auto !important;
      left: 0 !important;
    }

    .dropdown-user-profile .upgradePlan-block {
      &::before {
        right: auto;
        left: -70px;
      }

      &::after {
        right: auto;
        left: -150px;
      }
    }

    .dropdown-user-profile .settings-block .form-switch {
      padding-left: 2.5em;
      padding-right: 0;

      .form-check-input {
        float: left;
      }
    }

    @media (max-width: 575.98px) {
      .header-user-profile .pc-head-link .user-avatar {
        margin-left: 0;
      }

      .pc-h-item .pc-h-dropdown {
        right: 0 !important;
      }

      .pc-mega-menu .pc-mega-dmenu {
        left: 0 !important;
      }
    }
  }

  .pc-footer {
    margin-right: 260px;
    margin-left: 20px;
  }

  .page-header {
    h5 {
      border-left: 1px solid darken($body-bg, 10%);
      border-right: none;
      margin-right: 0;
      padding-right: 0;
      margin-left: 8px;
      padding-left: 8px;
    }
  }

  // responsive menu

  .pc-sidebar {
    @media (max-width: 1024px) {
      left: auto;
      right: -260px;
      transition: all 0.15s ease-in-out;

      &.mob-open {
        right: 0;
      }
    }
  }

  // ========== angular material =======//

  .mat-vertical-content-container {
    margin-right: 36px;
    margin-left: auto;
  }

  .mat-stepper-vertical-line::before {
    left: auto;
    right: 0;
  }

  // bootstrap component

  ol:not([class]),
  ul:not([class]) {
    padding-left: 0;
    padding-right: 2rem;
  }

  dl,
  .list-inline,
  .list-group,
  .list-unstyled {
    padding-right: 0;
    padding-left: 0;
  }

  dd {
    margin-right: 0;
  }

  .ph-duotone {
    direction: ltr;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    float: right;
  }

  .dropdown-menu {
    text-align: right;
  }

  .dropdown .dropdown-item i {
    margin-right: 0;
    margin-left: 10px;
  }

  .alert-dismissible {
    padding-right: $alert-padding-x;
    padding-left: 3.75rem;

    .btn-close {
      right: auto;
      left: 0;
    }
  }

  .accordion-button::after {
    margin-right: auto;
    margin-left: 0;
  }

  .was-validated .form-select:valid:not([multiple]):not([size]),
  .was-validated .form-select:valid:not([multiple])[size='1'],
  .form-select.is-valid:not([multiple]):not([size]),
  .form-select.is-valid:not([multiple])[size='1'],
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size='1'],
  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size='1'] {
    background-position:
      left 0.75rem center,
      center left 2.75rem;
    padding-right: 0.75rem;
  }

  .was-validated textarea.form-control:invalid,
  .was-validated textarea.custom-select:invalid,
  .was-validated textarea.dataTable-selector:invalid,
  .was-validated textarea.dataTable-input:invalid,
  textarea.form-control.is-invalid,
  textarea.is-invalid.custom-select,
  textarea.is-invalid.dataTable-selector,
  textarea.is-invalid.dataTable-input,
  .was-validated textarea.form-control:valid,
  .was-validated textarea.custom-select:valid,
  .was-validated textarea.dataTable-selector:valid,
  .was-validated textarea.dataTable-input:valid,
  textarea.form-control.is-valid,
  textarea.is-valid.custom-select,
  textarea.is-valid.dataTable-selector,
  textarea.is-valid.dataTable-input {
    background-position: top calc(0.375em + 0.4rem) left calc(0.375em + 0.4rem);
    padding-left: calc(1.5em + 1.6rem);
    padding-right: 0.75rem;
  }

  .was-validated .form-control:invalid,
  .was-validated .custom-select:invalid,
  .was-validated .dataTable-selector:invalid,
  .was-validated .dataTable-input:invalid,
  .form-control.is-invalid,
  .is-invalid.custom-select,
  .is-invalid.dataTable-selector,
  .is-invalid.dataTable-input,
  .was-validated .form-control:valid,
  .was-validated .custom-select:valid,
  .was-validated .dataTable-selector:valid,
  .was-validated .dataTable-input:valid,
  .form-control.is-valid,
  .is-valid.custom-select,
  .is-valid.dataTable-selector,
  .is-valid.dataTable-input {
    background-position: left calc(0.375em + 0.4rem) center;
    padding-left: calc(1.5em + 1.6rem);
    padding-right: 0.75rem;
  }

  .list-inline-item:not(:last-child) {
    margin-left: 0.5rem !important;
    margin-right: auto;
  }

  .cropper {
    direction: ltr;
  }

  .earning-card {
    .round {
      &.small {
        right: auto;
        left: -15px;
      }

      &.big {
        right: auto;
        left: -95px;
      }
    }

    &.dashboard-card-small {
      .round {
        &.small {
          right: auto;
          left: -130px;
        }

        &.big {
          right: auto;
          left: -180px;
        }
      }
    }
  }

  .total-income-card {
    &::before {
      left: -130px;
      right: auto;
      background: linear-gradient(234deg, rgb(144, 202, 249) -14.02%, rgba(144, 202, 249, 0) 77.58%);
    }

    &.warning {
      &::before {
        background: linear-gradient(234deg, rgb(255, 193, 7) -14.02%, rgba(144, 202, 249, 0) 70.5%);
      }
    }
  }

  .total-income-card::after {
    left: -180px;
    right: auto;
  }

  .order-card {
    .card-icon {
      right: auto;
      left: 14px;
    }
  }

  .social-widget-card i {
    right: auto;
    left: 14px;
  }

  .user-activity-card .u-img .profile-img {
    right: auto;
    left: -10px;
  }

  .latest-update-card .card-body .latest-update-box:after {
    right: 82px;
    left: auto;
  }

  .task-card {
    li {
      padding-left: 0;
      padding-right: 30px;

      .task-icon {
        right: 3px;
        left: auto;
      }
    }

    .task-list {
      &:after {
        right: 10px;
        left: auto;
      }

      &:before {
        right: 3px;
        left: auto;
      }
    }
  }

  .new-cust-card .align-middle .status {
    left: 0;
    right: auto;
  }

  .apexcharts-legend-marker {
    margin-left: 3px;
  }

  .apexcharts-tooltip {
    direction: ltr;
    text-align: left;
  }

  .btn-group {
    .btn {
      border-radius: var(--bs-btn-border-radius) !important;

      &:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      &:not(:first-child) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      &:first-of-type {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    }

    > .btn-group:not(:first-child) {
      > .btn {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  .btn-group-vertical {
    > .btn-group:not(:first-child) {
      > .btn {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }
    }
  }

  .btn-page {
    .btn-group {
      .btn {
        &.btn-outline-secondary {
          &:last-child {
            border-left: 1px solid $secondary;
            border-right: none;
          }
        }

        &:first-child {
          border-left: none;
        }

        &.btn-outline-danger {
          &:last-child {
            border-left: 1px solid $danger;
          }
        }
      }

      label.btn-outline-secondary {
        &:first-of-type {
          border-right: 1px solid $secondary;
          border-left: none;
        }
      }
    }
  }

  ul.pagination {
    padding-right: 0;
  }

  .page-item {
    &:not(:first-child) .page-link {
      margin-left: 0;
      margin-right: -1px;
    }

    &:last-child .page-link {
      border-top-left-radius: var(--bs-pagination-border-radius);
      border-bottom-left-radius: var(--bs-pagination-border-radius);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:first-child .page-link {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--bs-pagination-border-radius);
      border-bottom-right-radius: var(--bs-pagination-border-radius);
    }
  }

  .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.255em;
  }

  .modal-header .btn-close {
    margin: calc(var(--bs-modal-header-padding-y) * -0.5) auto calc(var(--bs-modal-header-padding-x) * -0.5)
      calc(var(--bs-modal-header-padding-y) * -0.5);
  }

  .toast-header .btn-close {
    margin-right: var(--bs-toast-padding-x);
    margin-left: calc(var(--bs-toast-padding-x) * -0.5);
  }

  .notifier-container {
    right: auto;
    left: 4px;

    .notifier:not(.shown) {
      transform: translateX(-100%);
    }
  }

  select.form-control,
  select.custom-select,
  select.dataTable-selector,
  select.dataTable-input,
  .form-select {
    background-position: left $form-select-padding-x center;
  }

  .form-select {
    padding-right: $input-padding-x;
    padding-left: $form-select-indicator-padding;
  }

  .form-check {
    padding-left: 0;
    padding-right: $form-check-padding-start;

    .form-check-input {
      float: right;
      margin-right: $form-check-padding-start * -1;

      &.color-select {
        margin-right: 0px;
      }
    }

    &.color-checkbox {
      margin-right: 8px;
      padding-right: 0px;
    }
  }

  .address-check {
    .form-check {
      padding-right: 0;
    }
  }

  .address-btns {
    justify-content: flex-end !important;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value,
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    margin-right: 15px;
  }

  .ng-select .ng-arrow-wrapper {
    padding-right: 5px;
  }

  .notifier__notification--material .notifier__notification-button {
    margin-right: 10px;
    margin-left: -10px;
  }

  select.custom-select,
  select.dataTable-selector,
  .form-select {
    padding-left: $form-select-indicator-padding;
    padding-right: $form-select-padding-x;
  }

  .form-switch {
    padding-left: 0;
    padding-right: $form-switch-padding-start;

    .form-check-input {
      margin-right: $form-switch-padding-start * -1;
      background-position: right center;

      &:checked {
        background-position: left center;
      }
    }
  }

  .custom-switch-v1.form-switch {
    padding-right: 2.9em;

    .form-check-input {
      margin-right: -2.9em;
    }
  }

  .input-group {
    > * {
      border-radius: $input-border-radius !important;
    }

    &:not(.has-validation) {
      > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
      > .dropdown-toggle:nth-last-child(n + 3),
      > .form-floating:not(:last-child) > .form-control,
      > .form-floating:not(:last-child) > .form-select {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    $validation-messages: '';

    @each $state in map-keys($form-validation-states) {
      $validation-messages: $validation-messages + ':not(.' + unquote($state) + '-tooltip)' + ':not(.' + unquote($state) + '-feedback)';
    }

    > :not(:first-child):not(.dropdown-menu):not(.form-floating)#{$validation-messages},
    > .form-floating:not(:first-child) > .form-control,
    > .form-floating:not(:first-child) > .form-select {
      margin-right: -$input-border-width;
      margin-left: 0;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .form-floating > label {
    left: auto;
    right: 0;
  }

  .form-check-inline {
    margin-right: 0;
    margin-left: 1rem;

    &.color-checkbox {
      margin: 0;
    }
  }

  .form-floating > .form-control:focus ~ label,
  .form-floating > .datatable-input:focus ~ label,
  .form-floating > .custom-select:focus ~ label,
  .form-floating > .dataTable-selector:focus ~ label,
  .form-floating > .dataTable-input:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .datatable-input:not(:placeholder-shown) ~ label,
  .form-floating > .custom-select:not(:placeholder-shown) ~ label,
  .form-floating > .dataTable-selector:not(:placeholder-shown) ~ label,
  .form-floating > .dataTable-input:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label,
  .form-floating > .datatable-selector ~ label {
    transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);
    transform-origin: right;
  }

  .choices[data-type*='select-multiple'] .choices__button,
  .choices[data-type*='text'] .choices__button {
    margin: 0 8px 0 -4px;
    border-left: none;
    border-right: 1px solid rgba(255, 255, 255, 0.35);
  }

  .choices[data-type*='select-one']:after {
    right: auto;
    left: 11.5px;
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: auto;
    left: 0;
  }

  .NgxEditor__Content blockquote {
    padding-right: 16px;
    border-right: 3px solid $gray-600;
    border-left: 0;
    padding-left: 0px;
  }

  .editor-toolbar,
  .CodeMirror {
    direction: ltr;
    text-align: left;
  }

  .dataTable-sorter::before,
  .dataTable-sorter::after {
    right: auto;
    left: 4px;
  }

  .dataTable-dropdown label select,
  .datatable-dropdown label select {
    margin-right: 0;
    margin-left: 8px;
  }

  .dataTables_length select.form-select {
    padding-right: 0.7rem;
    padding-left: 30px;
  }

  .dataTables_scrollHeadInner,
  .dataTables_scrollFootInner {
    padding-right: 0 !important;
    width: calc(100% - 17px) !important;
  }

  table.dataTable {
    thead,
    tfoot {
      th,
      td {
        text-align: right;
      }
    }

    .form-control {
      padding-left: 30px;
      padding-right: 0.75rem;
    }
  }

  table.dataTable thead > tr > th.sorting:before,
  table.dataTable thead > tr > th.sorting:after,
  table.dataTable thead > tr > th.sorting_asc:before,
  table.dataTable thead > tr > th.sorting_asc:after,
  table.dataTable thead > tr > th.sorting_desc:before,
  table.dataTable thead > tr > th.sorting_desc:after,
  table.dataTable thead > tr > th.sorting_asc_disabled:before,
  table.dataTable thead > tr > th.sorting_asc_disabled:after,
  table.dataTable thead > tr > th.sorting_desc_disabled:before,
  table.dataTable thead > tr > th.sorting_desc_disabled:after,
  table.dataTable thead > tr > td.sorting:before,
  table.dataTable thead > tr > td.sorting:after,
  table.dataTable thead > tr > td.sorting_asc:before,
  table.dataTable thead > tr > td.sorting_asc:after,
  table.dataTable thead > tr > td.sorting_desc:before,
  table.dataTable thead > tr > td.sorting_desc:after,
  table.dataTable thead > tr > td.sorting_asc_disabled:before,
  table.dataTable thead > tr > td.sorting_asc_disabled:after,
  table.dataTable thead > tr > td.sorting_desc_disabled:before,
  table.dataTable thead > tr > td.sorting_desc_disabled:after {
    left: 10px;
    right: unset;
  }

  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting_asc_disabled,
  table.dataTable thead > tr > th.sorting_desc_disabled,
  table.dataTable thead > tr > td.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting_asc_disabled,
  table.dataTable thead > tr > td.sorting_desc_disabled {
    padding-right: 0.75rem;
    padding-left: 26px;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0;
    margin-right: 0.5em;
  }

  div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
  }

  div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:first-child {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:last-child {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  div.dataTables_wrapper {
    div.dataTables_length,
    div.dataTables_filter,
    div.dataTables_info,
    div.dataTables_paginate {
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }

  table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
  table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
    padding-left: 0.75rem;
    padding-right: 30px;
  }

  table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
  table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
    left: auto;
    right: 5px;
  }

  .pc-icon-checkbox {
    padding-right: 0;
  }

  #cke5-inline-demo {
    .demo-row {
      .demo-row__half {
        &:first-of-type {
          padding-right: 0;
          padding-left: 0.5rem;
        }

        &:last-of-type {
          padding-right: 0.5rem;
          padding-left: 0;
        }
      }
    }
  }

  .mail-wrapper {
    .mail-menulist {
      margin-right: 0;
      margin-left: var(--bs-gutter-x);
    }
  }

  .mail-option .mail-buttons {
    right: auto;
    left: 5px;
    transform-origin: left;
  }

  .nav {
    padding-right: 0;
  }

  .chat-wrapper {
    .chat-userlist {
      margin-right: 0;
      margin-left: var(--bs-gutter-x);
    }

    .chat-userinfo {
      margin-right: var(--bs-gutter-x);
      margin-left: 0;
    }
  }

  .ecom-wrapper {
    .ecom-filter {
      margin-right: var(--bs-gutter-x);
      margin-left: 0;
    }

    .ecom-offcanvas.show .ecom-filter {
      margin-right: 0;
    }
  }

  .table-card .card-body,
  .table-body.card-body {
    .table {
      tr {
        td,
        th {
          &:first-child {
            padding-right: 25px;
            padding-left: 0.75rem;
          }

          &:last-child {
            padding-right: 25px;
            padding-left: 0.75rem;
          }
        }
      }
    }
  }

  .order-timeline {
    @include media-breakpoint-down(md) {
      .timeline-progress {
        &:after,
        &:before {
          transform: translate(50%);
          left: auto;
          right: 25px;
        }
      }
    }
  }

  .mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone {
    margin-left: 0;
    margin-left: 8px;
  }

  .was-validated .form-select:valid:not([multiple]):not([size]),
  .was-validated .form-select:valid:not([multiple])[size='1'],
  .form-select.is-valid:not([multiple]):not([size]),
  .form-select.is-valid:not([multiple])[size='1'],
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size='1'],
  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size='1'] {
    background-position:
      left 0.75rem center,
      center left 2.75rem;
  }

  .dataTable-table th a {
    margin-left: 16px;
    padding-right: 0;
  }

  .auth-main .auth-wrapper .auth-form img {
    padding-left: 15px;
    padding-right: 0;
  }

  .pc-kanban-wrapper .pc-kanban-column:not(:last-child) {
    margin-right: 0;
    margin-left: var(--bs-gutter-x);
  }

  #tree-msg {
    text-align: left;
  }

  .tns-outer {
    direction: ltr;
  }

  .slider {
    direction: ltr;
  }

  #BC .slider-handle {
    margin-left: -12px;
    margin-right: auto;
  }

  .notifier-close {
    left: 4px;
    right: auto;
  }

  .list-group {
    &[class*='list-group-horizontal'] {
      flex-direction: column;
      justify-content: flex-end;
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        &.list-group-horizontal#{$infix} {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .coming-soon-wrapper .side-animation-block .bottom-img {
    transform: rotatey(180deg);
  }
}
