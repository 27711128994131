// =======================================
//   List of variables for Preset color
// =======================================
$brand-color1: linear-gradient(83.31deg, #1de9b6 21.22%, #1dc4e9 88.54%);
$brand-color2: linear-gradient(206.48deg, #a389d4 11.14%, #899ed4 104.6%);
$brand-color3: linear-gradient(207.92deg, #0398f2 11.42%, #38b9e7 106.55%);
$brand-color4: linear-gradient(162.57deg, #c5ecf9 11.95%, #c4b4e4 97.32%);

$preset-colors: (
  preset-1: (
    body: #e3f2fd,
    primary: #2196f3,
    primary-dark: #1e88e5,
    primary-200: #90caf9,
    primary-800: #1565c0,
    secondary-light: #ede7f6,
    secondary: #673ab7,
    secondary-dark: #5e35b1,
    secondary-200: #b39ddb,
    secondary-800: #4527a0
  ),
  preset-2: (
    body: #eceff1,
    primary: #607d8b,
    primary-dark: #546e7a,
    primary-200: #b0bec5,
    primary-800: #455a64,
    secondary-light: #e0f2f1,
    secondary: #009688,
    secondary-dark: #00897b,
    secondary-200: #80cbc4,
    secondary-800: #00695c
  ),
  preset-3: (
    body: #ecedf1,
    primary: #203461,
    primary-dark: #1c2f59,
    primary-200: #909ab0,
    primary-800: #132145,
    secondary-light: #fde8ef,
    secondary: #ec407a,
    secondary-dark: #ea3a72,
    secondary-200: #f6a0bd,
    secondary-800: #e42a5d
  ),
  preset-4: (
    body: #eceff1,
    primary: #16595a,
    primary-dark: #135152,
    primary-200: #8bacad,
    primary-800: #0c3e3f,
    secondary-light: #f8f0e5,
    secondary: #c77e23,
    secondary-dark: #c1761f,
    secondary-200: #e3bf91,
    secondary-800: #b36115
  ),
  preset-5: (
    body: #e3e8e8,
    primary: #173e43,
    primary-dark: #14383d,
    primary-200: #8b9fa1,
    primary-800: #0d282c,
    secondary-light: #e8f6f5,
    secondary: #3fb0ac,
    secondary-dark: #39a9a5,
    secondary-200: #9fd8d6,
    secondary-800: #299792
  ),
  preset-6: (
    body: #e2e5e8,
    primary: #0a2342,
    primary-dark: #091f3c,
    primary-200: #8591a1,
    primary-800: #05152b,
    secondary-light: #e6f4f1,
    secondary: #2ca58d,
    secondary-dark: #279d85,
    secondary-200: #96d2c6,
    secondary-800: #1b8a70
  ),
  preset-7: (
    body: #eeedfd,
    primary: #3f51b5,
    primary-dark: #3949ab,
    primary-200: #9fa8da,
    primary-800: #283593,
    secondary-light: #eeedfd,
    secondary: #3f51b5,
    secondary-dark: #3949ab,
    secondary-200: #9fa8da,
    secondary-800: #283593
  )
);
$dark-preset-colors: (
  preset-1: (
    body: #111936
  ),
  preset-2: (
    body: #0e1b23
  ),
  preset-3: (
    body: #0a0f23
  ),
  preset-4: (
    body: #010606
  ),
  preset-5: (
    body: #030708
  ),
  preset-6: (
    body: #051327
  ),
  preset-7: (
    body: #1a223f
  )
);

$blue-100: #bedcf6;
$blue-200: #93c4f1;
$blue-300: #67aceb;
$blue-400: #479be6;
$blue-500: #2689e2;
$blue-600: #2281df;
$blue-700: #1c76da;
$blue-800: #176cd6;
$blue-900: #0d59cf;

$indigo-100: #d1b7fb;
$indigo-200: #b388f9;
$indigo-300: #9458f6;
$indigo-400: #7d34f4;
$indigo-500: #6610f2;
$indigo-600: #5e0ef0;
$indigo-700: #530cee;
$indigo-800: #4909ec;
$indigo-900: #3805e8;

$purple-100: #d1c4e9;
$purple-200: #b39ddb;
$purple-300: #9575cd;
$purple-400: #7e58c2;
$purple-500: #673ab7;
$purple-600: #5f34b0;
$purple-700: #542ca7;
$purple-800: #4a259f;
$purple-900: #391890;

$pink-100: #f8c5dd;
$pink-200: #f49fc6;
$pink-300: #ef78af;
$pink-400: #eb5b9d;
$pink-500: #e83e8c;
$pink-600: #e53884;
$pink-700: #e23079;
$pink-800: #de286f;
$pink-900: #d81b5c;

$red-100: #fcc7c3;
$red-200: #faa19b;
$red-300: #f77b72;
$red-400: #f65f54;
$red-500: #f44336;
$red-600: #f33d30;
$red-700: #f13429;
$red-800: #ef2c22;
$red-900: #ec1e16;

$orange-100: #fbe9e7;
$orange-200: #febf8a;
$orange-300: #fea55b;
$orange-400: #fd9137;
$orange-500: #fd7e14;
$orange-600: #fd7612;
$orange-700: #fc6b0e;
$orange-800: #fc610b;
$orange-900: #fc4e06;

$yellow-100: #fff8e1;
$yellow-200: #ffe083;
$yellow-300: #ffd451;
$yellow-400: #ffca2c;
$yellow-500: #ffc107;
$yellow-600: #ffbb06;
$yellow-700: #ffb305;
$yellow-800: #ffab04;
$yellow-900: #ff9e02;

$green-100: #b9f6ca;
$green-200: #80e4a9;
$green-300: #4dd987;
$green-400: #26d06d;
$green-500: #00c853;
$green-600: #00c24c;
$green-700: #00bb42;
$green-800: #00b439;
$green-900: #00a729;

$teal-100: #bcefe0;
$teal-200: #90e4cb;
$teal-300: #63d9b6;
$teal-400: #41d1a7;
$teal-500: #20c997;
$teal-600: #1cc38f;
$teal-700: #18bc84;
$teal-800: #13b57a;
$teal-900: #0ba969;

$cyan-100: #c5eff3;
$cyan-200: #9fe4eb;
$cyan-300: #78d9e2;
$cyan-400: #5bd1dc;
$cyan-500: #3ec9d6;
$cyan-600: #38c3d1;
$cyan-700: #30bccc;
$cyan-800: #28b5c6;
$cyan-900: #1ba9bc;

$white: #ffffff;
$gray-50: #f8fafc;
$gray-100: #eef2f6;
$gray-200: #e3e8ef;
$gray-300: #cdd5df;
$gray-400: #ced4da;
$gray-500: #697586;
$gray-600: #4b5565;
$gray-700: #364152;
$gray-800: #343a40;
$gray-900: #121926;
$black: #000000;
$dark: #111936;
