@import 'kanban';
@import 'mail';
@import 'e-commerce';
@import 'chat';

.kanban-tabs,
.profile-tabs {
  border-bottom: 0;
  .nav-item {
    margin-bottom: -0px;

    .nav-link {
      position: relative;
      padding: 0.7rem 0;
      margin: 0 1rem;
      font-weight: 500;
      color: var(--bs-body-color);

      i {
        font-size: 18px;
      }
      .material-icons-two-tone {
        font-size: 24px;
      }
      &:after {
        content: '';
        background: var(--bs-primary);
        position: absolute;
        transition: all 0.3s ease-in-out;
        left: 50%;
        right: 50%;
        bottom: -1px;
        height: 2px;
        border-radius: 2px 2px 0 0;
      }
    }
  }

  .nav-link:hover {
    border-color: transparent;
    background-color: transparent;
    .material-icons-two-tone {
      background-color: var(--bs-primary);
    }
  }

  .nav-item.show .nav-link,
  .nav-link.active {
    border-color: transparent;
    background-color: transparent;
    color: var(--bs-primary);
    .material-icons-two-tone {
      background-color: var(--bs-primary);
    }
    &:after {
      left: 0;
      right: 0;
    }
  }

  &.social-profile {
    .nav-link:hover {
      color: var(--bs-body-color);
    }
  }
}
.account-tabs {
  border-bottom: 0;
  .nav-item {
    margin-bottom: -0px;

    .nav-link {
      position: relative;
      padding: 0.7rem 0;
      margin: 0 1rem;
      font-weight: 500;
      color: var(--bs-border-color);
      display: flex;
      align-items: center;
      .material-icons-two-tone {
        font-size: 24px;
      }
    }
  }

  .nav-link:hover {
    border-color: transparent;
    color: var(--bs-primary);
    h5 {
      color: var(--bs-primary);
    }
    .material-icons-two-tone {
      background-color: var(--bs-primary);
    }
  }

  .nav-item.show .nav-link,
  .nav-link.active {
    border-color: transparent;
    background-color: transparent;
    color: var(--bs-primary);
    h5 {
      color: var(--bs-primary);
    }
    .material-icons-two-tone {
      background-color: var(--bs-primary);
    }
  }
}
.img-post-title {
  background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
}
.form-search {
  position: relative;
  i {
    position: absolute;
    top: 12px;
    left: 15px;
    font-size: 20px;
  }
  .form-control {
    padding-left: 50px;
  }
}
.follower-card {
  background: $gray-50;
  border: 1px solid var(--bs-border-color);

  &.card-style-1,
  &.card-two {
    img {
      width: 72px;
    }
  }
  &:hover {
    border-color: var(--bs-primary);
  }
  .friend-btn {
    &:not(:hover) {
      border-color: var(--bs-border-color);
      background: var(--bs-card-bg);
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin: 0 4px;
    }
  }
  &.card-two {
    background: $gray-50;
  }
}
.gallery-card {
  background: #fafafa;
  border: 1px solid var(--bs-border-color);
}
.profile-back-card {
  background: $gray-50;
  border: 1px solid var(--bs-border-color);
  overflow: hidden;
  text-align: center;
  .img-user_profile {
    width: 72px;
    margin: -50px auto 0px;
  }

  .btn i {
    font-size: 20px;
  }
}
.order-details {
  .btn i {
    font-size: 24px;
  }
}
.user-group {
  img {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    z-index: 2;
    transition: all 0.1s ease-in-out;
    border: 2px solid $card-bg;
    + img {
      margin-left: -14px;
    }
    &:hover {
      z-index: 5;
    }
  }
}
.invoice-card {
  max-width: 700px;
  margin: 0 auto;
}

.final-table-background {
  background: var(--bs-body-bg);
}
.order-timeline {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .timeline-progress {
    position: relative;
    height: 100%;
    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &:before {
      top: 0;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 4px solid var(--bs-card-border-color);
    }
    &:after {
      top: 25px;
      width: 2px;
      height: calc(100% - 25px);
      border-radius: 3px;
      background: var(--bs-card-border-color);
    }
    &.active {
      &:before {
        border-color: var(--bs-primary);
      }
      &:after {
        background: var(--bs-primary);
      }
    }
    &.half-active {
      &:before {
        border-color: var(--bs-primary);
      }
    }
  }
  @include media-breakpoint-down(md) {
    position: relative;
    .timeline-progress {
      position: static;
      &:after,
      &:before {
        left: 25px;
      }
    }
  }
}
.contact_list {
  @media (max-width: 575.98px) {
    .media {
      display: block;
      a {
        display: inline-block;
        vertical-align: middle;
      }
      > div {
        margin: 10px 0 0 70px;
        width: 100%;
      }
    }
  }

  .btn.btn-sm i {
    font-size: 18px;
    vertical-align: text-bottom;
  }
}

.colorpick-eyedropper-input-trigger {
  display: none;
}

.choose-color {
  border: none;
}

.text-color-pick {
  border: none;
  background: none;
}
