quill-editor {
  width: 100%;
}

.ql-toolbar {
  background-color: rgb(245, 245, 245);
  border-color: rgb(189, 189, 189);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ql-container {
  border-color: rgb(189, 189, 189) !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .ql-editor {
    min-height: 250px;
  }
}

.NgxEditor__MenuBar {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.NgxEditor {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
