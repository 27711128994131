table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1.05rem 0.75rem;
}
.dataTables_length,
.dataTables_filter {
  label {
    display: flex;
    align-items: center;
    select,
    input {
      padding: 10px 20px;
      border: 1px solid #ced4da;
      margin: 0 10px;
      border-radius: 0.2rem;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      &::-moz-placeholder {
        color: #aaaeb3;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #aaaeb3;
      }

      &::-webkit-input-placeholder {
        color: #aaaeb3;
      }
    }
  }
}

div.dt-buttons > .dt-button,
div.dt-buttons > div.dt-button-split .dt-button {
  background: $primary;
  border: 1px solid $primary;
  color: $white;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
}

.dataTables_wrapper {
  .dt-buttons {
    display: inline-flex;
    vertical-align: middle;
    margin-bottom: 5px;
  }
  .dt-button {
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    + .dt-button {
      margin-left: -1px;
    }
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: $secondary;
      color: $white;
      box-shadow: none;
    }

    &.active {
      background: $secondary;
      color: $white;
      border: 1px solid $secondary;
      box-shadow: none;
    }
  }
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #dceff7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  border: 1px solid $primary !important;
  background-color: $primary !important;
  color: $white;
  cursor: default;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: shift-color($primary, $soft-bg-level) !important;
  border: 1px solid shift-color($primary, $soft-bg-level) !important;
}

button.dt-button:hover:not(.disabled) {
  background: shift-color($primary, $soft-bg-level) !important;
  border: 1px solid shift-color($primary, $soft-bg-level) !important;
  color: $primary !important;
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
    no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
