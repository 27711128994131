.feed-card {
  overflow: hidden;

  h6 {
    margin-top: 7px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;

    > span {
      font-size: 11px;
    }

    &:hover {
      color: $primary;
    }
  }

  .feed-icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 8px 9px;
  }

  .card-body {
    position: relative;
    padding-left: 25px;

    &.p-t-0.p-b-0 {
      padding-left: 12px;
    }

    .border-feed {
      transition: all 0.3s ease-in-out;
      position: relative;

      i {
        position: absolute;
        top: calc(50% - 20px);
        left: 15px;
        right: 0;
        text-align: center;
        color: $white;
        transition: all 0.3s ease-in-out;

        &.material-icons-two-tone {
          background-color: $white;
        }
      }
    }
  }

  &:hover {
    .border-feed.bg-primary {
      background-color: transparentize($primary, 0.7) !important;

      i {
        color: $primary;

        &.material-icons-two-tone {
          background-color: $primary;
        }
      }
    }

    .border-feed.bg-danger {
      background-color: transparentize($danger, 0.7) !important;

      i {
        color: $danger;

        &.material-icons-two-tone {
          background-color: $danger;
        }
      }
    }

    .border-feed.bg-success {
      background-color: transparentize($success, 0.7) !important;

      i {
        color: $success;

        &.material-icons-two-tone {
          background-color: $success;
        }
      }
    }

    .border-feed.bg-warning {
      background-color: transparentize($warning, 0.7) !important;

      i {
        color: $warning;

        &.material-icons-two-tone {
          background-color: $warning;
        }
      }
    }
  }
}

.social-widget-card {
  border-top: none;
  color: $white;

  i {
    position: absolute;
    right: 14px;
    top: 24px;
    font-size: 48px;
    opacity: 0.4;
    transition: all ease-in 0.3s;
  }

  &:hover i {
    opacity: 1;
    transform: scale(1.1);
  }
}

.comp-card {
  i {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    padding: 16px 0;
    font-size: 18px;
  }
}

.proj-progress-card {
  .progress {
    height: 6px;
    overflow: visible;
  }

  @media only screen and (max-width: 992px) {
    h6 {
      margin-top: 15px;
    }

    .progress {
      margin-bottom: 20px;
    }
  }
}

.widget-visitor-card {
  overflow: hidden;

  i,
  svg {
    font-size: 100px;
    color: $white;
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -20px;
    opacity: 0.3;
    left: -10px;
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out;
    transform: rotate(25deg);
  }

  &:hover {
    svg {
      transform: rotate(0deg) scale(1.4);
      opacity: 0.5;
    }
  }
}

.to-do-list {
  i {
    color: #ccc;
    font-size: 20px;
    opacity: 0;
  }

  &:hover i {
    opacity: 1;
    transition: opacity ease-in 0.3s;
  }
}

.widget-last-task {
  .img-radius {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  span.float-right {
    font-size: 11px;
  }
}

.user-activity-card {
  .u-img {
    position: relative;

    .cover-img {
      width: 40px;
      height: 40px;
    }

    .profile-img {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -5px;
      right: -10px;
      font-size: 12px;
    }
  }
}
.latest-posts-card {
  a {
    transition: all 0.3s ease-in-out;

    &:hover h6 {
      color: $primary;
    }
  }

  .latest-posts-img {
    width: 90px;
    height: 80px;
  }
}

.latest-activity-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      .update-meta {
        z-index: 2;
        min-width: 170px;

        .update-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          padding: 12px 13px;
          font-size: 16px;
          color: $white;
          margin-left: 10px;
        }
        @media only screen and (max-width: 575px) {
          z-index: 2;
          min-width: 100%;
          text-align: left !important;
          margin-bottom: 15px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px;
        }
      }
    }
  }
}
.latest-update-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        background: #ebebeb;
        height: 86%;
        width: 1px;
        top: 0;
        left: 80px;
        z-index: 1;
      }

      .update-meta {
        z-index: 2;

        .update-icon {
          border-radius: 50%;
          text-align: center;
          padding: 7px 8px;
          font-size: 24px;
          color: $white;

          &.ring {
            border-width: 3px;
            width: 15px;
            height: 15px;
            padding: 0;
            display: block;
            background: $white;
          }
        }

        i.update-icon {
          margin-left: 4px;
          margin-right: 4px;
        }

        img.update-icon {
          padding: 0;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.card {
  &.product-table,
  &.latest-table,
  &.recent-ticket-table {
    .table {
      tr {
        height: 54px;
      }
    }
  }
  &.incoming-request {
    .card-body {
      div {
        &:hover {
          background-color: transparentize($secondary, 0.97);
        }
      }
    }
  }
}

.task-card {
  .task-list {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background: $border-color;
      height: 100%;
      width: 2px;
      top: 0;
      left: 10px;
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      background: $border-color;
      height: 15px;
      width: 15px;
      bottom: -14px;
      left: 3px;
      z-index: 2;
      border-radius: 50%;
    }

    li {
      margin-bottom: 30px;
      padding-left: 30px;
      position: relative;

      .task-icon {
        position: absolute;
        left: 3px;
        top: 1px;
        border-radius: 50%;
        padding: 2px;
        color: $white;
        min-width: 15px;
        min-height: 15px;
        z-index: 2;
      }
    }
  }
}

.new-customer-card {
  img {
    width: 40px;
  }

  h6 {
    margin-bottom: 0;
  }

  .align-middle {
    position: relative;

    .status {
      position: absolute;
      right: 0;
      top: 19px;
      font-size: 13px;

      &.active {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $success;
      }
    }
  }
}
