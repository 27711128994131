// ============================
//     Common layout css start
// ============================

body {
  background-color: $white;
}

.pc-container {
  position: relative;
  top: 88px;
  margin-left: 260px;
  margin-right: 20px;
  min-height: calc(100vh - 141px);
  background: $gray-100;
  border-radius: 8px;

  .coded-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    @include media-breakpoint-down(xl) {
      &.container {
        max-width: 100%;
      }
    }
  }

  .page-header + .row {
    padding-top: 24px;
  }
  .page-header + .coded-content {
    padding-top: calc(30px + 55px);
  }
}

.page-header {
  display: flex;
  align-items: center;
  min-height: 55px;
  padding: 13px 25px;
  box-shadow: $card-shadow;
  background: $card-bg;
  border-radius: $border-radius;
  margin-bottom: 24px;

  .page-block {
    width: 100%;
  }

  .page-header-title {
    display: inline-block;
  }

  h5 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 20px;
  }

  .breadcrumb {
    padding: 0;
    display: inline-flex;
    margin-bottom: 0;
    background: transparent;
    font-size: 14px;

    a {
      color: var(--bs-body-color);
    }

    .breadcrumb-item {
      a:hover {
        color: $primary;
      }

      + .breadcrumb-item::before {
        position: relative;
        top: 2px;
      }

      &:last-child {
        opacity: 0.75;
      }
    }

    svg {
      width: 14px;
      height: 14px;
      vertical-align: baseline;
    }
  }
}

.upgrade-card {
  .card-body {
    padding-bottom: 100px;
    z-index: 1;
    position: relative;
  }

  > .container {
    z-index: 5;
    position: relative;
    margin-top: -60px;
  }

  .up-price-table {
    td {
      border-color: $body-bg;
    }

    th {
      border: none;

      + th {
        border-radius: 5px 5px 0 0;
        padding: 20px 0;
      }
    }

    tbody {
      tr {
        &:first-child td {
          border-top: none;
        }
      }
    }

    td,
    th {
      text-align: right;

      + td,
      + th {
        text-align: center;
      }

      + td {
        border-left: 1px solid $body-bg;
      }
    }
  }
}

@media (max-width: 1024px) {
  .pc-container {
    margin-left: 10px;
    margin-right: 10px;
    .page-header + .row,
    .page-header + .coded-content,
    .coded-content {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .page-header {
    position: relative;
    left: 0;
    top: 0;
  }
}
@include media-breakpoint-down(sm) {
  .pc-container {
    .coded-content {
      padding: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .page-header {
    padding: 13px 20px;

    h5 {
      margin-bottom: 10px;
    }

    .breadcrumb {
      display: flex;
      margin-bottom: 0;
    }

    .text-md-right {
      margin-top: 5px;
    }
  }
}

.navbar-content {
  position: relative;
}

// Common layout css end
.footer-wrapper,
.coded-content {
  &.container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
}
