.tag-chips {
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 2px;
  background: $gray-100;
  border: 1px solid $gray-600;
  input {
    background: transparent;
  }
  .ng2-tag-input {
    line-height: 30px;
    border-radius: 2px;
    padding: 0;
    border-bottom: none !important;
    &.ng2-tag-input--focused {
      border-bottom: none !important;
    }
    tag {
      color: $white !important;
      background: $primary !important;
      padding: 5px 12px;
      border-radius: 2px;
      line-height: 37px;
      margin-top: 5px;
      margin-right: 10px;
      &.tag--editing {
        color: $black !important;
        background: $white !important;
      }
    }
    &.bootstrap {
      @extend tag;
    }
    &.minimal {
      tag {
        border-radius: 15px !important;
      }
    }
    &.dark tag {
      background: $dark !important;
    }
    &.danger tag {
      background: $danger !important;
    }
    &.success tag {
      background: $success !important;
    }
    &.warning tag {
      background: $warning !important;
    }
    &.minimal-dark tag {
      border-radius: 15px !important;
      background: $dark !important;
    }
    &.minimal-danger tag {
      border-radius: 15px !important;
      background: $danger !important;
    }
    &.minimal-success tag {
      border-radius: 15px !important;
      background: $success !important;
    }
    &.minimal-warning tag {
      border-radius: 15px !important;
      background: $warning !important;
    }
  }
  .tag__text {
    line-height: 2;
    font-weight: 400;
  }
  delete-icon svg {
    height: 14px !important;
    line-height: 1;
    vertical-align: top !important;
    margin-top: 7px;
    path {
      fill: $white !important;
    }
  }
  .ng2-tags-container tag {
    padding: 3px 9px !important;
  }
  delete-icon:hover {
    transform: none !important;
  }
  .ng2-tag-input__text-input {
    height: auto !important;
  }
}

.tag-select {
  .bootstrap tag {
    padding: 5px 10px !important;
    height: auto !important;
    z-index: 0;
    line-height: 25px !important;
    background: $primary !important;
    delete-icon {
      &:hover {
        transform: scale(1);
      }
      svg {
        height: 27px !important;
      }
    }
  }
  tag-input-form {
    padding: 0px 10px 0px 10px !important;
    input {
      background: transparent !important;
    }
  }
  .ng2-tag-input {
    border-bottom: 1px solid $gray-600 !important;
    border: 1px solid $gray-600;
    padding: 5px !important;
    border-radius: 4px;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  z-index: 10000;
}
